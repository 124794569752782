@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Mulish&display=swap);

/* font-family: 'Inter', sans-serif; */
/* font-family: 'Mulish', sans-serif; */
* {
  margin: 0;
  padding: 0;
  border: none;
}
.main_loading{
 min-height: 100vh;
 width: 100%;
 display: -webkit-flex;
 display: flex;
 left: 0;
 top: 0;

 -webkit-align-items: center;

         align-items: center;
 -webkit-justify-content: center;
         justify-content: center;
 background-color: #373a6c;
position:fixed ; z-index: 50000;
}

.lang{  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #0000002b;
  height: 72px;
  width: 72px;
  font-weight: 600;
padding:10px;
  z-index: 20;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  color: #373a6c;
  border-radius:100%;
  background-color: #ffffff85; }

.follow-us{text-align: center; padding: 20px 0px 30px;}

.follow-us h4{color:#ffc108;}

.follow-us a{ margin:0px 5px; background-color: rgba(255, 255, 255, 0.09); color: #fff; font-size: 24px; height: 45px; width:45px;
   border-radius: 100%;  }
   .follow-us a:hover{ background-color: #fff;  color: #f84b05; }

button:focus{outline: none;}

.flex{
 display: -webkit-flex;
 display: flex;
}
.block {
  display: block;
}

.defaulpad {
  padding: 80px 0px;
}
.language{
  position: absolute;
    right: 0px;
    top: 0;
}

.doicon{display: none;}
body {
  font-size: 16px;
  background-color: #fff;
   font-family: 'Mulish', sans-serif;
  color:#000000;
}
a.dbg{color: #fff;}
a.dbg:hover{color: #cdff00;}

a.textlink{color: #0a6f43;}
a.textlink:hover{color: #69b443;}

p, .list li{
  font-size: 16px;
  line-height: 25px;
   font-family: 'Mulish', sans-serif;
   font-weight:normal;
   
}
p b{font-weight: bold;}

.pdr{padding-right: 22px;}
.pdl{padding-left: 22px;}

.mtop20 {
  margin-top: 20px;
}
.mtop15{margin-top: 15px;}

.paddinTB40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}
.textlink{ color: #0a6f43;}
.textlink:hover{color: #08b162;}
input:hover, textarea:hover, textarea:focus,
input:focus {
  stroke: 0;
  outline: 0;
  border: 0;
}

input[type=submit],
a {
  transition: all 300ms ease-in;
}
.mobimg{ display: none !important;}
.mobreqest .quotes{ text-transform: uppercase;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;

  box-shadow: 0 0 0px 1000px rgba(41, 36, 36, 0) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}


.drkform input:-webkit-autofill,
.drkform input:-webkit-autofill:hover,
.drkform input:-webkit-autofill:focus,
.drkform textarea:-webkit-autofill,
.drkform textarea:-webkit-autofill:hover,
.drkform textarea:-webkit-autofill:focus,
.drkform select:-webkit-autofill,
.drkform select:-webkit-autofill:hover,
.drkform select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
}
.trnsintion {
  transition: all 400ms ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h3 {
font-family: 'Inter', sans-serif; 
}
h2{padding-bottom: 10px; padding-top: 10px;}

h2:nth-child(1){padding-top: 0px;}

h3 {
  font-size: 19px;
  font-weight: 900;
}

h5{font-size: 18px; font-weight: 700; padding: 10px 0px;}

h1 {
  font-size: 24px;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.link {
  position: relative;
  min-width: 165px;
  text-align: center;
  padding: 15px 25px;
  color: #444673;
  background: #f2f2f2 !important;

  font-size: 16px;
  background: rgb(250,107,6);

border-radius:30px;
  display: inline-block;
  font-family: 'Inter', sans-serif;
text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  overflow: hidden;
}
.link.donates{display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center;   padding:10px 25px;}
.link.donates i{ font-size:25px ; margin-right: 4px;}

.link i, .link span{position: relative; z-index: 1;}
.link::after{position: absolute; left: 0; top: 0; content: '';  
   background: linear-gradient(45deg, rgba(250,107,6,1) 0%, rgba(255,193,8,1) 100%); width: 100%; height: 100%; opacity: 0; -webkit-transform: scale(0.7); transform: scale(0.7);
   transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1); border-radius:30px;}
.link:hover:after{ opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}


.link:hover{ color: #fff !important;   }

.storylink{
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.10);
  height: 100%;
  width: 100%;
  border-radius:20px;
}
.storylink a{ height: 60px; width: 60px; font-size: 25px; color: #fff; border: 1px solid rgba(255, 255, 255, 0.1); border-radius: 100%; background-color: rgba(0, 0, 0, 0.21);}
.storylink:hover{background-color: #444673b4;}
.storylink:hover a{background-color: #fff; color: #f6731e; -webkit-transform: scale(1.3); transform: scale(1.3);}

.header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  border-bottom: 1px solid rgb(255 255 255 / 8%);
  background-color: #373a6c00;
  padding: 5px 0px;
  z-index: 1002;
  transition: all 400ms ease-in-out;

}

.header.active { background-color: #373a6c;}

.nbar{padding-top: 5px !important; padding-bottom: 5px !important}

.header.active .topbar{
max-height: 0px;
padding: 0; overflow: hidden;
}



.header .container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.menubutton {
height: 39.6px;
width: 39.6px;
  z-index: 4;
  position: relative;
  background:transparent;
  text-align: center;
border: 0px;
border-radius: 100%;
vertical-align: middle;
background-color: rgba(255, 255, 255, 0.089);
display: none;
}

.menubutton span {
  display: block;
  width:15px;
  height: 2px;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: rgb(255, 255, 255);
  margin: 3px auto;
}

.menubutton.opened span:first-child {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);}

  .menubutton.opened span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.menubutton.opened span {
  margin: -2px auto auto;
  background-color: #fff;
}

.menubutton.opened span:nth-child(2) {
  visibility: hidden;
  height: 0;
}
.menubutton.opened{background:rgba(250,107,6,1) ; background: linear-gradient(45deg, rgba(250,107,6,1) 0%, rgba(255,193,8,1) 100%);}



.brand img {
  height: 64px;
  vertical-align: middle;
}


.navebar,
.nav{
  display: -webkit-flex;
  display: flex;
  list-style: none;
  -webkit-align-items: center;
          align-items: center;
}
.nav li {
  padding: 0px 8px;

  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 500;

  cursor: default;
  transition: all 300ms ease-in;
}


.otherNav li{border-radius: 5px;
  background: #f3f3f3; padding: 10px 15px; margin-right: 15px;}

.nav li:hover, .nav li a:hover, .nav a.activelink{ color: #fa9806 ;}

.nav li:nth-child(1) {
  padding-left: 0px;
}
.nav a {

  color: rgb(255, 255, 255);
  text-transform: uppercase;
}
.nav i{vertical-align: middle;}

.navebar a.selected,
.navebar a:hover {
  opacity: 1;
}


/* submen */

.submenu{  display: block; } 
.submenu:hover{overflow: visible; }
.megamenu {
 transition: all 300ms ease-in-out;
  margin-top:10vh;
  position:fixed;
visibility:hidden;
width:100vw;
left: 0;
padding-top: 26px;
opacity: 0;
max-height: 0px; overflow: hidden;

}

.submenu:hover .megamenu{margin-top:0vh; visibility:visible; opacity: 1;  max-height: 1000px; overflow:visible;  }
.backdrop{ position: relative;
  padding: 15px 0px; background: transparent;
  background-color: rgb(55 58 108 / 30%);
  -webkit-backdrop-filter: blur(10px) brightness(0.5);
          backdrop-filter: blur(10px) brightness(0.5);

  border-radius: 0px;
   }
 
   .header.active .backdrop{   background-color: rgb(55 58 108 /90%);}

.megamenu a.subtabs{ padding: 15px; border-radius: 5px; background-color: rgba(255, 255, 255, 0.021);}
.megamenu a.subtabs i{font-size:30px; background-color: rgba(3, 22, 77, 0.1) !important;
  border-radius: 100%; display:-webkit-inline-flex; display:inline-flex; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center;
  height: 50px !important; min-width: 50px !important; color: #ffffff; margin-left: 0px; margin-right: 10px; }
.megamenu a.subtabs h1{font-size: 16px; font-weight: 500; 
  text-transform: capitalize; line-height: 1.2;
  margin-bottom: 5px; color: #ffffff; font-family: 'Roboto', sans-serif;}
.megamenu a.subtabs p{line-height: 1; font-size: 12px; color: rgba(255, 255, 255, 0.801);}
.megamenu a.subtabs:hover, a.subtabs.active{background-color: rgba(255, 255, 255, 0.932);}
.megamenu a.subtabs:hover p, a.subtabs.active p{color: rgba(3, 21, 77, 0.6);}
.megamenu a.subtabs:hover h1, a.subtabs.active h1, .megamenu a.subtabs:hover i, a.subtabs.active i{ color: #f84b05;}

/* donate */

.big-title .i-angle-down{display: none;}
.inputBox{display: none;}
.big-title {
  text-align: center;
  background-color: #fff;
  padding: 40px 40px;
  margin: auto;
  border-radius: 20px;
  height: 100%;

}

.big-title.acountdetails{display: -webkit-flex;display: flex; -webkit-flex-direction: column; flex-direction: column; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center;}

.big-title h1{

  color: rgb(0, 0, 0);
  text-align: center;
  font-weight:400;
  font-size:20px;
  text-transform: uppercase;
  padding: 10px 25px;
  background-color: rgb(255, 217, 0);
  display: inline-block;
  margin:0 auto ;
}
.big-title h5{display: none;}
.amount{width: 100%;
  background-color: #00000011;
   border: 1px solid rgba(0, 0, 0, 0.103);
  padding: 30px;
  font-size: 25px; font-weight: 700;
  text-align: center;
  border-radius: 13px;}

  .qrcode{display: block;}
 .qrcode img{ margin-bottom: 0px; max-width: 200px !important; }
 .qrcode h4{margin-bottom:10px;}

  .donate{ padding: 18px 23px; 
    font-size: 16px;
    margin-top: 0px;
    background-color: #f4970e;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    display:none;
    border: 0;
    border-radius: 7px;
  }
  .donate:hover{ background-color: #7cc528; color: #fff;
  }

  .big-title img{width: 100%; max-width: 300px; margin-top: 10px;}
.big-title h2{
  color: rgb(251 125 6);
  font-size: 26px;
  font-weight: 700;

}
.big-title label{ color: #4a4d74; font-size: 24px; font-weight: 700; letter-spacing: 1px; margin-bottom: 25px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* banner */
.staticBanner{padding: 0%; line-height: 0;  background-color: #373a6c; position: relative;}
.staticBanner img{ width: 100%; border-radius: 0; height:100vh; object-fit: cover; }
.overlay{ position: absolute; left: 0; top: 0; z-index: 2; color: #fff; 
  background: rgba(86,88,125, 0.75);

  background: 
url(/static/media/rightDesign.a8202a85.svg),  url(/static/media/leftDesign.76f97401.svg),
  linear-gradient(63deg, rgba(86,88,125,0.75) 0%, rgba(55,58,108,0.75) 100%); height: 100vh; width: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; padding-top: 50px; background-repeat: no-repeat;
background-position:100.3% 100.2%, -0.3% 100.2%, left top;
} 
  
.bulls{
background-color: #2f3261;
padding: 0px; position: relative;
}
.backdrops{ position: absolute; left: 0; right: 0; height: 100%; width: 100%; }
.backdrops img{  width: 100%; height: 100%; border-radius: 0px; opacity: 0.3 ; object-fit: cover;}

.conetnts{
  padding: 70px 0px; position: relative; z-index: 2;
}

.conetnts ul{color: rgba(255, 255, 255, 0.823); padding-left: 15px; list-style:circle;}
.conetnts ul li{padding: 5px 0px; font-size: 16px;}

.conetnts ul li::marker{font-size: 20; font-weight: 900; color: #ff9553;}

.conetnts h3{ font-size: 27px; margin: 10px 0px; color: #ff9553; font-weight: 600; letter-spacing: 1px; margin-bottom: 20px; 
  position: relative; line-height: 1.5;}
  .conetnts h3::after{ content: ''; position: absolute; left: 0px; height: 4px; width: 60px; background-color:#ff9553; bottom: -12px;}
  
.overlay h1{ font-size:45px; color: #fff; font-weight: 900; letter-spacing: 1px; line-height: 1.2;}
.overlay h4{font-size: 20px;  text-align: center; color: #fff; font-weight: 500; letter-spacing: 1px; }
.overlay img{ width: 100%; max-width: 700px;
   object-fit:unset !important; height: auto; margin: auto;} 
.banner{position: relative;}
.overlay img, .overlay h4, .overlay a{margin: 15px 0px;}
.overlay .link{width: -webkit-fit-content;width: -moz-fit-content;width: fit-content;
  
  box-shadow: 0px 0px 0px 0px #f6901e63;
  margin-left: auto;  margin-right: auto;  -webkit-animation: blink 1s ease-in-out infinite;  animation: blink 1s ease-in-out infinite;  }
.overlay p{ text-align: justify; line-height: 1.3; font-size: 16px;
}
@-webkit-keyframes blink {
  0%{ box-shadow: 0px 0px 0px 0px #f6901e63;}
  50%{  box-shadow: 0px 0px 0px 5px #f6911e;}
 100%{  box-shadow: 0px 0px 0px 0px #f6901e63;}
}
@keyframes blink {
  0%{ box-shadow: 0px 0px 0px 0px #f6901e63;}
  50%{  box-shadow: 0px 0px 0px 5px #f6911e;}
 100%{  box-shadow: 0px 0px 0px 0px #f6901e63;}
}


.forms{border-radius: 20px; background-color: rgba(255, 255, 255, 0.178); background-color: rgba(255, 255, 255, 0.555); padding:25px;
   -webkit-backdrop-filter:blur(15px) brightness(1.6);
           backdrop-filter:blur(15px) brightness(1.6);}
.forms hr{background-color:rgba(5, 5, 5, 0.08); height: 1px; width: 100%; margin-top: 5px; margin-bottom: 10px;}
.forms h2{padding-left: 2px;  color: #1e98bb;}
.ftab{padding: 15px 10px; background-color: #fff; color: #5c5c5c; font-size: 14px; border-radius: 5px; height: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
.ftab.page{padding: 10px 10px;}
.ftab select, .ftab input, .ftab textarea{width: 100%; color: #5c5c5c; font-size: 14px;}
.ftab select:focus, .ftab select:hover{ outline: none; border: 0;}
.ftab.page button{ transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1); height: 25px; width: 25px; border-radius: 3px; color: #5c5c5c; font-size: 20px; font-weight: 700; background-color: rgba(0, 0, 0, 0.1); }
.ftab.page span{width: 70px; margin: 0px 2px; border-radius: 3px; text-align: center; height: 25px; line-height: 25px;  background-color: rgba(0, 0, 0, 0.1);}
.ftab.page button:hover{background-color: #1E8EBB; color: #fff; }
.forms p{padding-top: 0px; margin-top: 5px; margin-bottom: 5px; color: #5e5e5e;}
.ftab.page2 input{ width: auto; margin-right: 5px;}
.ftab.page2{ background-color: transparent; padding: 0px;}
.ftab.page2 label{ margin-right:25px;}

.attach{padding: 8px 10px; background-color: rgba(255, 255, 255, 0.726);}
.attach:hover{ background-color: #1E8EBB ; color: #fff; transition: all 400ms ease-in; cursor: pointer;}
.attach:hover input{color: #fff;}
.attach input{  max-width:150px; text-align: center;}
::-webkit-file-upload-button{ display: none;}
.forms button.link{border-radius: 10px; width: 100% !important; color: #fff; margin-right: 0px;}



.bannerimg{
  height: calc(100vh - 105px);
  object-fit: cover;
  min-height: 545px;
  object-position: bottom right;
 
}
.bannerimg2{
  height: calc(100vh - 105px);
  object-fit: cover;
  min-height: 545px;
  object-position: center left;
}
  .bannercon{
    position: absolute;
    left: 0;
    top:0;
    padding-top: 75px;
    padding-bottom: 25px;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .BannerTwo .bannercon{padding-top: 100px;}
  
  .bannercon p{color: #fff;}
  .bannercon h1{color: #fff; font-size:3vw; margin-bottom: 20px;}
  .control-dots{display: none;}
  .bannercon .link{margin: 13px 0px 13px 0;}
  .BannerOne .bannercon{background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.35) 22%, rgba(0,0,0,0.1) 84%);}

    .BannerTwo .bannercon{background: rgba(0,0,0,0.31);}

    .BannerOne h1{ font-size: 24px; color: #fff;  margin-bottom: 0px;}
    .BannerOne h2{ font-size: 22px; color: #f9f111;}
    .BannerOne p{font-size: 14px; line-height: 20px;}
    .BannerOne p b{font-weight: 700;}
    .bntags p{ font-size:12px; line-height: 18px; font-style: italic; }

    .BannerTwo .bannercon h1,  .BannerTwo .bannercon h2,  .BannerTwo .bannercon h3,  .BannerTwo .bannercon div {text-align: center;}
    .BannerTwo .bannercon h1{font-size: 24px; margin-bottom: 10px;  margin-bottom:15px; }
    .BannerTwo .bannercon h2{font-size: 20px; color: #043d55;  margin-bottom:15px;}
    .BannerTwo .bannercon div a{margin-bottom:8%;}
    .BannerTwo .bannercon h3{color: #fff; font-size: 22px; margin-bottom:1%;}
    .BannerTwo .bannercon p{font-size: 16px; font-style: italic; color: #f9f111; }
    .three .bannercon div a{ margin-bottom: 0px;}
    .three .bannercon h1{font-size: 23px; line-height: 1.3;}
    .three .bannercon h3{color: #fff; font-size: 18px; margin-bottom:8%;}
   
  .carousel.carousel-slider .control-arrow {
    width: 51px;
    height: 51px;
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 100%;
    opacity: 1 !important;
    top: unset !important;

    z-index: 2;
    
    text-align: center;
    list-style: 51px;
    font-family: 'ews' !important;
    color: #272727 !important;
    font-size: 25px;
    transition: all 500ms ease-in;
}
.mobile .carousel.carousel-slider .control-arrow {  width:35px;
  height:35px; }
.carousel .thumbs-wrapper{margin: 0px !important;}
.textimonials .carousel-slider{ padding-bottom: 70px;}
.carousel.carousel-slider .control-arrow:hover{  background: #1E97BB !important; color: #eef1e6 !important;}

.carousel.carousel-slider .control-arrow.control-disabled{ display: block !important; 
  background-color: rgba(255, 255, 255, 0.2)!important; opacity: 1 !important; color: #ffffff52 !important;}

.carousel .control-next.control-arrow{ right:0px !important;}
.carousel .control-prev.control-arrow{ left:0px;}
.carousel .control-next.control-arrow:before {
  content: "\e904" !important;
  border:none !important;
}
.carousel .control-prev.control-arrow:before {
  content: "\e902" !important;
  border:none !important ;
}

.carousel::before{ height: 1px; left: 60px; content: ''; display: block;  width:calc(100% - 120px); background-color: rgba(0, 0, 0, 0.082);
  
  position: relative;  bottom: 27px;}


  .mobile{border-radius: 32px;
    background: #333; max-width:300px; width: 100%; margin: auto;
    padding: 13px;
  }
  .screen{border-radius: 20px; overflow: hidden;}
    .mobile img{width: 100%; max-width: unset !important; border-radius: 0px;}
    .screen .rws{ background-color: #333; padding-bottom: 40px;}
/* .pagebanner */

.pagebanner{ padding-top:85px;  background-color: #373a6c ;  }
.pagebanner h1{color: #f0f0f0df; font-weight: 600; letter-spacing:1px; 
  background: rgb(246,147,30);
  background: linear-gradient(288deg, rgba(246,147,30,1) 0%, rgba(246,99,30,1) 100%);
  -webkit-background-clip: text; width: -webkit-fit-content; width: -moz-fit-content; width: fit-content;
  -webkit-text-fill-color: transparent; }
.pagebanner .container{ padding: 10px 0px; background-repeat: no-repeat;
   background-position:bottom right; background-size:auto 120%;}

   .qoute.mypick2 .quotes{font-size: 19px; text-align-last:justify !important;}

   .graybg{background-color: rgba(0, 0, 0, 0.056); text-align: center;}

   .column{
overflow: hidden;
  border-radius: 15px;
  background: #ececec;
  margin: 20px 0px;
   }
   .column div{border-bottom: 1px solid rgba(0, 0, 0, 0.185); }
   .column div:nth-child(odd){border-right: 1px solid rgba(0, 0, 0, 0.185); }
   .column div:nth-child(1),  .column div:nth-child(2){ background-color: #0a6f43;}
   .column div:nth-last-child(1),   .column div:nth-last-child(2){border-bottom: 0px;}

   .column p{  padding: 15px 10px !important; display: block; height: 100%;}
   .column p b{color: #fff;}

/* presets */
/* sections */
section, footer{padding: 65px 0px;}
section.nonepd{padding: 0px 0px;}
section img{border-radius: 10px;}
section.qoute{ padding: 50px 0px; background-color: #fff;}
section.conLight, footer{  background: #fff;}
section.conGreenish{ background: #F0F0F0;}

section.conDark{ background: #000000;}
section.conDark h2, section.conDark p, .white-text p, .white-text h2, .white-text, .conDark .list{
  color: #fff;
  }
  /* speciclHedeng */

  .speciclHedeng{background-color: #fff; padding: 40px;}
  .speciclHedeng h2 {
    max-width: 607px;
    margin: auto;
    line-height: 1.5;
}
/* Team*/
.teammember{margin-top: 10px;}
.team{
 background-color: #fff; 
 border-radius: 10px; overflow: hidden;
 box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.team img{ margin: 30px auto 5px; height: 170px; height: 170px; border-radius: 100%; border: 1px solid rgba(0, 0, 0, 0.134); object-fit: cover; object-position: center;}
.team h2{ text-align: center; padding: 20px 20px 5px 20px; color: #373a6c; }
.team p{text-align: center; padding: 0px 20px 30px 20px; letter-spacing: 2px; color: #fa6b06; }

  /* tags */

  .tags span{ display: inline-block; margin: 3px; margin-right: 5px; margin-left: 0; padding: 2px 0px; padding-right: 5px; border-right: 3px solid #0a6f43;}
  .tags span:nth-last-child(1){border: 0px; margin-right: 0; padding-right: 0px;}
  /* MypickTwo */
 .mypick2 img{
  min-width: 100%;
  max-width:130px ;
 }
 section.mypick2{-webkit-backface-visibility: hidden;backface-visibility: hidden;
  padding-top: 0;
  height: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
  padding-bottom: 30px;}
  .mypick2 .quotes{ margin-bottom: 28px;}
 
 .list{padding:10px 15px; padding-left: 20px; }



 .list li::marker{ font-size: 24px; color: #0a6f43;}
 
 .list.blackPointer li:nth-child(1){color:#0a6f43;}

 .ol li{padding: 5px 0px !important;}
 .list li{padding: 2px 0px; line-height: 20px; position: relative;  }

 .list li::before,  .list li::after{ position: absolute; content:'';left: -21.5px;
  top: 8.6px;
  border: 1px solid #0a6f43; 
  height: 9px;
  width: 9px; border-radius: 100%; -webkit-animation:crcl 2s linear infinite ; animation:crcl 2s linear infinite ; }
  .list li::after{-webkit-animation-delay: 1s;animation-delay: 1s;}

  .ol.list li::before,  .ol.list li::after{left: -21.5px;
    top: 11.7px;}


    .reab li{line-height: 25px; text-align: justify;}
    .reab li::after, .reab li::before{ display: none; }
    .reab.list li::marker{ font-size: 16px; color: #0a6f43; }

    .br100{ border-radius: 100%;}

    .ablist li::marker{font-weight: 700; }
    .ablist li{padding: 10px 0px; padding-left:5px;}
    .ablist li:nth-child(1){padding-top: 0px;}

    .ablist{ list-style-type: upper-alpha;}

 @-webkit-keyframes crcl{ 
  0%{ -webkit-transform: scale(0.0); transform: scale(0.0); opacity:1; }

   100%{ -webkit-transform: scale(2.5); transform: scale(2.5); opacity: 0; }
 }

 @keyframes crcl{ 
  0%{ -webkit-transform: scale(0.0); transform: scale(0.0); opacity:1; }

   100%{ -webkit-transform: scale(2.5); transform: scale(2.5); opacity: 0; }
 }


 /* about*/
 .homeabt{padding: 100px 0px;}
 h1.mainheading{ font-size:65px; font-weight:700; color: #fa6b06; line-height: 1.3; margin-bottom: 20px;}
 h1.mainheading span{ color: #35375d; font-weight: normal; }

 .smalltxt{ font-size:16px;  color: #444673; margin-top: 15px; margin-bottom: 10px; display: block;}

 .homeabt p{text-align: justify; line-height: 1.3; color: #5e5e5e; font-size: 18px; margin: 10px 0px;}
 .homeabt img{max-width: 220px; border-radius: 20px; position: relative; z-index: 1; top: -25px;}
 .homeabt .abtimg::after{ width: 326px; height:326px; position: absolute;
  content: ''; border-radius: 100%; bottom: calc(0px - 45px);
  background: linear-gradient(#f6931e 0%, #f68a1e 28.71%, #f6731e 74.83%, #f6631e 100%);
 }
 .homeabt a.youtube{ position: absolute; height: 50px; width: 50px;
   background-color: #fff; border-radius: 100%; z-index: 4; display: -webkit-flex; display: flex; -webkit-align-items: center ; align-items: center ; 
   font-size: 25px; bottom: 0px; box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5); color: #F6711E; 
   -webkit-justify-content: center; 
           justify-content: center; transition: all 200ms ease-in; -webkit-animation: yani 1s linear infinite; animation: yani 1s linear infinite;}
   .homeabt a.youtube i{margin-left: 3px; }

   .homeabt a.youtube:hover{ box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5);  -webkit-animation:unset;  animation:unset}

   @-webkit-keyframes yani {
     0%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);}
     65%{box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5);}
     100%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);}
   }

   @keyframes yani {
     0%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);}
     65%{box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5);}
     100%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5);}
   }
/* services */
.service{position: relative;}
.services{background-color: transparent; position: relative; z-index: 2;}
.gradiantOR{background: linear-gradient(90deg, #f6931e 0%, #f68a1e 28.71%, #f6731e 74.83%, #f6631e 100%); 
position: absolute; width: 100%; height: 60%;
}
.services{background-image:url(/static/media/hline.eaf4472d.svg);
background-size:auto 85%; background-repeat: no-repeat; background-position: center center, left top; }

.serv{ display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; background: #e3e3ee;
   margin:30px 10px; margin-bottom: 50px; border-radius: 20px; overflow: hidden;}
   .services .react-multi-carousel-item{transition: all 400ms linear;}
   .services .react-multi-carousel-track  .react-multi-carousel-item:nth-child(odd){margin-top:40px;}
   .services .react-multi-carousel-track  .react-multi-carousel-item--active:nth-child(even){margin-top:40px;}
   .services .react-multi-carousel-track  .react-multi-carousel-item--active:nth-child(odd){margin-top:0px;}
.serv img{width: 100%; border-radius: 0px;}
.imgs{position: relative; border-radius: 0px 0px 20px 20px; overflow: hidden;}
.imgs::after{ position:absolute; left: 0; top: 0; content: '';  width: 100%; height: 100%;
  background: linear-gradient(180deg, rgba(86,88,125,0) 0%, rgba(55,58,108,1) 100%);}

  .iconser{width: 100%; height: 0px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;  -webkit-justify-content: center;  justify-content: center; position: relative; z-index: 5;}
  .iconwraper{width: 74px;
    height: 74px;
    background: #fff; border-radius: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center;}
    .iconwraper img{ height: auto; max-width: 33px;}
  .serv h2{
    text-align: center; padding:40px 25px; padding-bottom: 30px;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #444673;
    }

    .serv:hover{background-color:rgba(55,58,108,1);}
    .serv:hover h2{color: #fff;}
    .serv:hover .iconwraper img{-webkit-filter: brightness(10);filter: brightness(10)}
    .serv:hover .iconwraper{background-color:rgb(64, 67, 124);}
    .serv:hover .imgs {background-color:rgba(55,58,108,1);}
    .serv:hover .imgs img{-webkit-transform: scale(1.1);transform: scale(1.1);}


    .serv, h2, div, img{transition:  all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);}
    /* react-multiple-carousel */
    .services .react-multiple-carousel__arrow--left {
      top: calc(100% - 43px) !important;
      left: calc(50% - 120px) !important;
  }
  .services .react-multiple-carousel__arrow--right {
    top: calc(100% - 43px) !important;
    right: calc(50% - 120px) !important;
}
 .react-multi-carousel-dot-list{ height: 43px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;}

    .react-multi-carousel-dot button{border: 0px !important; transition: all 400ms ease-in; background: #e3e3ee !important; }

    .react-multi-carousel-dot--active button, .react-multi-carousel-dot:hover button{ background-color:#f6631e !important ; }
 
    .react-multiple-carousel__arrow{ background: #444673 !important; transition: all 400ms ease-in;}
    .react-multiple-carousel__arrow:hover{background-color: #f6631e  !important;}
    .react-multiple-carousel__arrow::before{font-size: 16px !important;}
    .react-multi-carousel-list {
  
      padding-bottom: 21px;
  }

  .reviews .react-multiple-carousel__arrow--right{ right: 0px !important;}
  .reviews .react-multiple-carousel__arrow--left{left: 0px !important;}
  .reviewsbox{ padding: 30px 80px;}

  .reviews-txt{background-color: #F3F3F3; padding: 30px; margin: 30px 0px; min-height:calc(100% - 60px);  display: -webkit-flex;  display: flex; -webkit-flex-direction: column; flex-direction: column; -webkit-justify-content: center; justify-content: center; border-radius: 0px 20px 20px 0px;}
  .reviews-txt h2{ font-family: Inter;
    font-weight: bold;
    font-size: 30px;
    color: #f6711e;
    }
    .reviews-txt i{ color: #f6711e;
    font-size: 0px;
    height: 44px;
    margin-right: 10px;
    min-width: 44px;
    background-color: #fff;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;}
    .reviews-txt p{padding-top: 6px;}
    .reviews-txt .flex{margin-top: 10px;}
  .imagewraper{background-color: #F3F3F3;  min-height: 100%;}
  .reviews-txt i::before{
    font-size: 20px;
  }
  
img.beneficiary{ border-radius: 20px; width: 100%;  min-height: 100%; object-fit: cover; object-position: center;}
  
.services h1.mainheading{color: #fff;}
.services p{text-align: justify; line-height: 1.3; font-size: 18px;}

.stepsw{text-align: center;} 
.steps{
  background-color: #fff; text-align: center;
  padding: 25px; border-radius: 12px;
  margin-top: -20px;
}

.slink a{ background-color: rgba(68, 70, 115, 0.13); font-size: 50px;
   line-height: 0; height: 70px; width: 70px; border-radius: 100%;
    display: -webkit-flex;
    display: flex; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center; 
    color:#444673;
    text-align: center; box-shadow: 0px 0px 0px 5px rgba(68, 70, 115, 0.13); position: relative;
  }
  .slink a{left: 10%;}
  .slink a:nth-child(2){left:-10% !important;}
    .slink a::after, .slink a::before{ content: ''; border-radius: 100%;
      position: absolute; border: 1px solid rgba(68, 70, 115, 0.2);
      left: 0; top: 0; width: 100%; height: 100%; -webkit-transform: scale(1); transform: scale(1); -webkit-animation:slink 2s ease-in infinite; animation:slink 2s ease-in infinite;}
      .slink a::before{-webkit-animation-delay: 1s;animation-delay: 1s;}
      @-webkit-keyframes slink{
        0%{-webkit-transform: scale(1.4);transform: scale(1.4); opacity: 0;}
        40%{-webkit-transform: scale(1.4);transform: scale(1.4); opacity: 1;}
        100%{-webkit-transform: scale(2);transform: scale(2); opacity: 0;}
      }
      @keyframes slink{
        0%{-webkit-transform: scale(1.4);transform: scale(1.4); opacity: 0;}
        40%{-webkit-transform: scale(1.4);transform: scale(1.4); opacity: 1;}
        100%{-webkit-transform: scale(2);transform: scale(2); opacity: 0;}
      }

      .slink a:hover{ color:#fff; background-color:#f6631e;  box-shadow: 0px 0px 0px 10px #f6631e }
      .slink a:hover::after, .slink a:hover::before{
        border-color:hsla(24, 92%, 54%, 0.288) ; 
      } 

/* Purpose */




/* reviews */
.reviews{ background-color: #fff; padding-top: 10px;}
.reviews .steps{
height: 100%;
}
.reviews .row>*{min-height: 100%;}
.reviews .steps{ margin-top:10px;}
.textimonials h3, .textimonials p{text-align: justify; text-align-last: left;}
.textimonials hr{ height: 1px; margin: 10px 0px; background-color: rgba(0, 0, 0, 0.05);}
.textimonials::after{display: none;}



/* CHECKOUT */
.checkout a{ display: block; width: -webkit-fit-content; width: -moz-fit-content; width: fit-content; margin: 10px;}
.checkout img{width: 100%;}



/* watermark */
.watermark{background-size: cover; background-position:center; background-attachment: fixed;
  background-size: cover;
}


.grow h2{line-height: 2; letter-spacing: 2px;}

.focus h2.mainH{ text-align: center; margin-bottom: 15px;}



  .youare img{ border-radius: 100%;}


/* contact */
.contact label{
color: #373a6c !important;
font-weight: 700;
}
  .drkform{padding: 15px 20px; margin-bottom:10px;
     -webkit-backdrop-filter: blur(10px);
             backdrop-filter: blur(10px);  transition: all 300ms ease-in;    border: 1px solid rgba(255, 255, 255, 0.1); border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);}
  .drkform label{padding-right: 10px; font-size: 16px;
    margin-right: 10px;
    border-right: 1px solid rgba(255,255,255,0.3);}

    .drkform input, .drkform textarea{ background-color: transparent; font-family: 'Mulish', sans-serif; color: #fff; font-size: 16px; display: block; width: 100%;}

    .drkform:focus-within{  background-color: #0a6f43; }

    ::-webkit-input-placeholder { 
      color: rgb(138, 138, 138);
      opacity: 1; 
    }

    :-ms-input-placeholder { 
      color: rgb(138, 138, 138);
      opacity: 1; 
    }

    ::placeholder { 
      color: rgb(138, 138, 138);
      opacity: 1; 
    }
    
   :-ms-input-placeholder { 
    color: rgb(138, 138, 138);
    }
    
  ::-ms-input-placeholder { 
    color: rgb(138, 138, 138);
    }
    .contactimg img{max-width: 250px; border-radius: 100%;}

    .lgtform{ border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 10px;
      background-color: #fff;}
      .lgtform:focus-within label{ color: #fff; }

      .lgtform label{border-right-color:rgba(0, 0, 0, 0.3) ;}

      .lgtform input{color: #000;}
      .lgtform input:focus{color: #fff;}
      .form{margin-top: 15px;}

      .radio{padding: 15px 0px; margin-bottom:10px;}
      .radio div{margin-right: 20px;}
      .radio input{margin-right: 8px;}


      /* testimonials */

            /* Reviews */

            .greviews{ background-color: rgba(255, 255, 255, 1); border-radius: 15px; padding:40px; height: 100%;
              box-shadow: 5px 5px 20px #8bc34a11;
              position: relative;
            }
           
      
            .greviews::after{ position: absolute; height: 30px; width:30px; content: '\e977'; 
              border-radius: 0px 5px 5px 0px; font-size: 18px; font-family: 'ews' !important;
             background-color: #0a6f43; left: 0; top: 40px; color: #fff; line-height: 30px; text-align: center;}
      
            .greviews p{ position: relative;
              font-size: 14px;
              line-height: 1.7;
              text-align: left; text-align: justify;
              color: #6d6c6c;
              margin-bottom: 20px;
              font-style: italic;
              }
      /* 
              .greviews p::after, .greviews p::before{position: absolute;
                font-size: 50px; opacity: 0.5; 
                content: '"';}
      
                .greviews p::after{
          left: -10px;
                  top: -20px;
               } */
      
               .carousel .slider-wrapper.axis-horizontal .slider .slide {
            
                display: -webkit-flex !important;
            
                display: flex !important;
                -webkit-justify-content: center !important;
                        justify-content: center !important;
            }
      
              .greviews h2{font-size:18px; margin-bottom:0px; padding-bottom: 5px; }
              .greviews span{font-size: 14px; color: #0a6f43; }
              .usericon i{ display: -webkit-inline-flex; display: inline-flex; -webkit-align-items: center; align-items: center;
              -webkit-justify-content: center;
                      justify-content: center; height: 50px; width: 50px; border-radius: 100%; overflow: hidden; background-color: #0a6f43; color: #fff; font-size: 27px; margin-right: 10px;}
              .star{font-size: 24px; color: #ffb600;}
            

              /* .row div:nth-child(3n+2) .greviews{ background-color: rgba(255, 255, 255, 0.808); }

              .row div:nth-child(3n+0) .greviews{ background-color: #ffffff9c;}  */

           
/* footer */

footer{
  background: #444673 !important;
  padding-bottom: 0px;
}
.address-box{
  display: -webkit-flex;
  display: flex;
    padding: 25px 25px;
    background: #0000001f;
    border-radius: 20px;
    min-height: 111px;
    height: 100%;
}
.address-box .icon-container{
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.07);
  text-align: center;
  line-height: 45px;
  margin-right: 10px;
  border-radius: 100%;
}
.address-box .icon-container i{
  font-size: 20px;
color: #ffc108;
}
.address-box .content h4{
  color: #ffc108;
  margin-bottom: 5px;

}
.address-box .content p, .address-box .content p a{
  margin: 0;
  color: #dedede;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}
.address-box .content p a:hover{  color: #ffc108;}


.footer-bottom{
  background-color: #4c4e82;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
}
.footer-bottom p{
  color: #dedede;
  font-size: 15px;
  letter-spacing: 0.5px;
}
.footer-middle{
  padding:40px 0px 40px;
}
.footer-middle ul{
  list-style: none;
}
.footer-middle h2{
  color: #1eb3bb;
  font-size: 22px;
}
.footer-middle ul li{
  padding:7px 0px;
}
.footer-middle ul li a{
  color: #dedede;
  font-size: 15px;
}
.footer-middle ul li a:hover{   color: #ffc108;}
.footer-middle ul li i{
  color: #ffc108;
}
/* screen 1200 */

@media only screen and (max-width:1200px) {
  .watermark{background-attachment: unset; }

  .quotes{font-size: 18px; text-align-last: center !important;}
  .mypick2 .quotes{margin-bottom: 0px;}
  .brand img{ height: 50px;}
  .nav li {
    padding: 0px 5px;
    font-size: 14px;}
    .qoute.mypick2 .quotes {
  
      text-align-last: center !important;
  }
  .meShubham .flex br:nth-child(1), .meShubham .flex br:nth-child(2), .WhyMe .centeQuote br:nth-child(1){
    content: "";
    display: block;
    margin: -1em 0px; }
}

/* screen 992 */

@media only screen and (max-width:992px){ 
  .homeabt h1.mainheading {
    margin-top: 63px;
}
h1.mainheading{ font-size: 45px;}
  .homeabt{padding-bottom: 50px;}
  .header.active .backdrop{background-color: transparent !important;}
  .submenu .megamenu{margin-top:0vh; visibility:visible; opacity: 1 !important;  max-height: 1000px !important;  }
  .subtabs{display: -webkit-flex !important;display: flex !important; -webkit-flex-wrap: nowrap !important; flex-wrap: nowrap !important;}
  .address-box {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    padding: 20px 20px;
    background: #ffffff08;
    border-radius: 15px;
  }

  .address-box .content p, .address-box .content p a{font-size: 13px;}

.checkout .col-6 h2{ margin-top: 20px; padding-left: 20px;}
footer .flex{-webkit-flex-wrap: wrap;flex-wrap: wrap;} 
.overlay{padding-top: 0px; background-size: 150px auto, 150px auto, cover; }
.overlay img{width: 90%;}
  .quotes{font-size: 14px; text-align-last: center !important;}

  .menubutton {
    display: block;
    margin-left: 5px;
}
.flex.aic.mypick4{-webkit-align-items:flex-start;align-items:flex-start;}
.flex.aic.mypick4 img{ min-width:250px;}
.WorkWith .flex img{ min-width: 250px;}
.link {
  font-size: 14px;
min-width: unset;
}
  
  .nav{position: fixed;
    transition: all 400ms ease-in;
    height: 100%;
    display: block;
    z-index: 2;
    width: 100%;
    opacity: 0;
    overflow:auto;
    visibility: hidden;
    right: -100%;
    padding-top: 65px;
    padding-left: 20px;
    padding-right: 20px;
    top: 0px;
    background: transparent;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    --webkit-backdrop-filter: blur(12px);
    background-color: #373a6cd6;
    box-shadow: -2px -2px 10px rgb(0 0 0 / 20%);   
}


.nav.opened{right: 0px;
  top: 0;
  opacity: 1;
  visibility: visible;}

.nav li a, .nav li span{ padding-right: 20px !important;  display: block; color: #fff;}

  .navebar li a,  .navebar li {
    text-align: left;
    letter-spacing: inherit;
    font-size: 16px;
    padding: 0px 0px;
    padding: 10px 0px;
    color: #fff;
}

.megamenu {
  transition: all 300ms ease-in-out;
  margin-top: 0px;
  position: unset;
  visibility: visible;
width: 100%;
  padding-top: 5px;
  padding-left: 0px;
  opacity: 1;
}
.megamenu .container{     max-width: 100%;
  padding: 0px;
  width: 100% !important;}
.megamenu a{border: 0px; color: #fff !important;
 border-left: 1px solid rgba(255, 255, 255, 0.3); padding-left: 7px !important; line-height: 1.8;}
 .megamenu a:hover{color: #f9f111 !important;}

 .navebar li {
  padding: 5px 10px !important;
  border: 1px solid #ffffff2b;
  border-radius: 5px;
  margin-bottom: 5px;}

 .backdrop::after{display: none;}
.backdrop {
  padding: 0px 0px;
  background: transparent;
  border: 0px;
  -webkit-backdrop-filter: unset;
  backdrop-filter: unset;
  --webkit-backdrop-filter: unset;
  box-shadow: unset;
  border-radius: 0px;
  background-color: transparent;
}
.flex.ready{ -webkit-flex-wrap: wrap; flex-wrap: wrap;}
.wtBg::before{width: 100%;}
.flex.ready img{width: 100%;}
.flex.ready img:nth-last-child(1){margin-top: 15px;}
h2 {
  font-size: 1.2rem;
}
.ready p {
  font-size:16px;
}
.committed .flex{ -webkit-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse; }
.flex img{margin: 15px 0px; max-width: 100%; min-width: 250px;}
.mypick2 img{ min-width: unset;}

.coach .link {
  font-size: 16px;
  line-height: 1.3;
  height: 94px;
  margin: 12px 0px;
  padding: 15px 20px;
}
.greviews i img{max-width: 50px; min-width: unset;}
.greviews h2{font-size: 16px;}
.greviews span{font-size: 13px;}
}

@media only screen and (max-width: 768px) {
  .language{
    position: relative;}
.language select{
    padding: 8px;
    margin-left: 6px;
    border-radius: 20px;}
  .big-title.acountdetails {
-webkit-align-items: flex-start;
        align-items: flex-start;
   
}

.doicon{display: block; width: 50% !important; margin: auto;}
.overlay, .staticBanner img{ height: calc(100vh - 55px);}

.staticBanner .overlay img{ height: inherit;}
.lang{ 
  height:50px;
  width:50px;
  right: 15px; 
  bottom: 30px;
  }
  .lang img{ height: 30px; width: 30px;} 
  /* donate */
  .inputBox {
    display: block;
    margin: 10px 0px;
    border:2px solid #373a6c87;
    padding: 20px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    border-radius: 7px;
    color: #373a6c;
}
.inputBox:focus, .inputBox:hover{   border:2px solid #373a6c; color: #373a6c;}
  h1.mainheading.donateh {
    font-size: 24px;
    margin-bottom: 0;
}
  .big-title .i-angle-down {
    display: block;
    font-size: 40px;
    margin: 10px 0px;
    opacity: 0.5;
    -webkit-animation: angle 1s linear infinite;
            animation: angle 1s linear infinite;
  }

@-webkit-keyframes angle {
  0% {-webkit-transform: translateY(-5px);transform: translateY(-5px);}
    50% {-webkit-transform: translateY(5px);transform: translateY(5px);}
      100% {-webkit-transform: translateY(-5px);transform: translateY(-5px);} 
  }

@keyframes angle {
  0% {-webkit-transform: translateY(-5px);transform: translateY(-5px);}
    50% {-webkit-transform: translateY(5px);transform: translateY(5px);}
      100% {-webkit-transform: translateY(-5px);transform: translateY(-5px);} 
  }
  .mobe{display: block;}
.qrcode img {
display: none;
}
.big-title{border-radius: 20px;
  padding: 30px 30px;
  width: 95%;
}
.big-title h4{display: none;}
.big-title p{ display: none;}

.acountdetails.big-title p{display: block;}
.donate{display: block;}

  .radias b{font-size: 15px;}
  header {
    padding: 11px 0;
}
.big-title h1 {
  font-size: 24px;
}
.big-title h5 {
  display: block;
  font-weight: 700;
  font-size:25px;
  margin-bottom:20px;
  color: #505279;
}

  /* donate */

  .megamenu a.subtabs{     padding: 5px;}

.reviews-txt h2{font-size: 20px; text-align: center;}
.reviews-txt .flex{ -webkit-flex-wrap: nowrap; flex-wrap: nowrap;}
.reviews-txt{ margin: 0px; padding: 20px; border-radius: 0px 0px 10px 10px;}
img.beneficiary{border-radius: 10px 10px 0px 0px !important;}
.reviews-txt i {
  line-height: 35px;
  font-size: 0px;
  height: 35px;
  margin-right: 8px;
  min-width: 35px;}
  .reviewsbox {
    padding: 30px 35px;
}

  .reviews .react-multiple-carousel__arrow{min-width: 35px;
    min-height: 35px; border: 5px solid #fff;}
    
    .reviews .react-multiple-carousel__arrow::before{font-size: 12px !important;}

  .column p{text-align: left;}
  p.tags{text-align: left;}
  .tags br{display: none;}
  .tags span{ display: unset; }
  .flex{ -webkit-flex-wrap: wrap; flex-wrap: wrap;}
  .qoute .flex{ -webkit-justify-content: center; justify-content: center;}
  .pageContent {
    padding: 20px 15px;
}

section.mypick2 {
  height: 77px;
  padding-bottom: 18px;
}
section.mypick2 .flex{-webkit-flex-wrap: unset;flex-wrap: unset;}
section.mypick2 .flex img {
  margin-bottom: 0;
}
section.mypick2 .flex .pdl{padding-left: 20px !important;}
.qoute.mypick2 .quotes{    font-size: 13px; line-height: 1.5; text-align-last: center !important;}

  .pagebanner {
    padding-top: 65px;}

 

  .aboutHome .contents p {
    margin-top: 0;
}
footer .flex .text-right{text-align: center; }
footer .flex div{width: 100%;}
footer .flex div p{text-align: center;}

.aboutHome.aic{-webkit-align-items:flex-start;align-items:flex-start;}
  .banner .container {

-webkit-justify-content: center;

        justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px; 
}
.imtab {
  margin-left: 5px;
  margin-right: 5px;
}
.explore a img {
  width: 150px;
}
.explore .heading {
    display: none;
}
  .copyright {
    text-align: left;
}
  footer h3 {
    margin-bottom: 10px;
    margin-top: 25px;
}

  .container {
    width: calc(100% - 0px);
  }

  .navebar a {
    letter-spacing: inherit;
    font-size: 12px;
    padding: 0px 5px;
  }

  .headerRow {
    height: 50px;
  }

  h1 {
    font-size: 24px;
  }


  p {
    font-size: 14px;
  }


.pdl, .pdr{padding: 0px !important;}

.list li::before, .list li::after{
  background-color: #0a6f43;
}
.list li::marker{  color: #0a6f4300;}
.banner .container {
  padding-bottom: 100px;
}
.BannerTwo .bannercon {
  padding-top: 50px;
}
.bannercon h1{ font-size: 20px !important;}
.bannercon h2{ font-size: 18px !important;}
.bannercon h3{font-size: 16px !important;}
.bannercon p{font-size: 14px !important;}
.container {
  width: calc(100% -  0px) !important;
}
.hasTag p{ text-align: left;}

.coachingProgramBP p:nth-child(1){margin-bottom: 20px; }
input[type=submit].link{ font-size: 20px; border-radius: 100px; padding: 22px 40px;}
}

@media (max-width: 575.98px){

  .follow-us a {
    margin: 0px 2px;
    font-size: 18px;
    height: 35px;
    width: 35px; 
}

  .megamenu a.subtabs h1{
    font-size: 14px;
  }
  .megamenu a.subtabs p{font-size: 10px;}
  .footer-middle {
    padding: 0px 0px 40px;
}
  .services {
    background-size: 90% auto;
}
.slink{margin-top: 50px;}
.slink a{
  font-size: 40px;
  height: 55px;
  width: 55px;
  display: -webkit-flex;
  display: flex;
}
  .iconwraper {
    width: 60px;
    height: 60px;
}
.iconwraper img {
  max-width: 26px;
}
.imgs {
  border-radius: 0px 0px 10px 10px;
}
  h1.mainheading {font-size: 40px;}

  .homeabt p, .services p{font-size: 16px;}
  .homeabt{padding-top: 0px; padding-bottom: 40px;}
  .homeabt.page{padding-top: 30px; }
  .serv h2 {
    padding: 35px 20px;
    padding-bottom: 19px;
    font-size: 19px;

    line-height: 25px;
}
.react-multi-carousel-track .react-multi-carousel-item--active:nth-child(even) {
  margin-top:0px;
}
.react-multi-carousel-track .react-multi-carousel-item:nth-child(odd) {
  margin-top: 0px;
}
  .homeabt img{    top: -15px;}
  .abtimg{    max-width: 80%;
    margin: auto;}
  .serv{ 
    margin:30px 5px; margin-bottom: 50px; border-radius:10px;}
      /* react-multiple-carousel */
      .services .react-multiple-carousel__arrow--left {
        top: calc(100% - 43px) !important;
        left: 10px !important;
    }
    .services .react-multiple-carousel__arrow--right {
      top: calc(100% - 43px) !important;
      right: 10px !important;
  }


  section, footer{padding:0px 0px;}
  .services{
     padding: 40px 0px;
  }
  footer{padding-top: 20px;}
  
  .WhyMe p{ padding: 5px 0px;}
  .img50-50{display: -webkit-flex;display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;}
  .img50-50 img{margin: 5px;}
  .img50-50 img:nth-child(1), .img50-50 img:nth-child(2){  max-width:calc(50% - 10px) ; min-width: unset !important  ;}
  .imgHeightControl{width: 100%;}
  .imgHeightControl img{width: 100%; height: 200px; object-fit: cover; object-position: top;}
  .mobimg{ display: block !important;}
  .deskimg{ display: none !important;}
  .mobreqest .quotes{font-size: 12px; text-transform: capitalize;}
section.qoute{ padding: 35px 0px; }

.services .carousel .control-next.control-arrow {
    right: 22px !important;}
    .services .carousel .control-prev.control-arrow {
      left: 22px !important;
      right:unset !important;
  }
  .rowimg img{height: 120px;}
  .services .carousel.carousel-slider .control-arrow{bottom:0px !important;}

.react-multi-carousel-dot-list{
    min-height: 44px;
    -webkit-align-items: center;
            align-items: center;
  }
  .react-multi-carousel-dot button{ width: 8px !important;
    height: 8px !important;  }

  .link {
    padding: 7px 16px !important;
    line-height: 1.3;
    font-size: 12px;
    letter-spacing: unset;
}
  section.mypick2 {
    height:160px;
    padding-bottom: 18px;
    margin: 20px 0px;
}
.qoute.mypick2 .flex {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}
.qoute.mypick2 .flex {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}
.qoute.mypick2 .flex .pdl{padding-left: 0px !important;}
  .wtBg {
    padding: 25px 25px;
}
  .brand img{height: 32px;}
  .thumbnails.details {
    padding: 20px;
}
.smallthumbnails {
  padding: 15px 20px;}
.cotegory{padding:20px 0px}
  .aboutHome .contents p {
    margin-top: 10px;
}
.explore .link {
  line-height: 44px;
  padding: 0px 10px;
  font-size: 12px;
}
.explore .container {
  -webkit-justify-content: center;
          justify-content: center;
}
.pagebanner .container{padding: 5px 15px 8px 15px;}

.pagebanner .container h1{font-size: 18px;
  line-height: 1.2;}

.iheading {
  text-align: center;
  padding: 40px 0px;
}
.footer-bottom p{

  font-size: 12px;
  letter-spacing: 0px;
}
.graybg{padding: 30px 0px;}
}

.react-multi-carousel-dot:nth-last-child(1) button{margin-right: 0px;}
*, ::after, ::before {
    box-sizing: border-box;
    line-height: 1;
}
.flex{ display: -webkit-flex; display: flex;}
.d-column{ -webkit-flex-direction: column; flex-direction: column;}
.flexGrow-1{-webkit-flex-grow: 1;flex-grow: 1;}
.aic{-webkit-align-items: center;align-items: center;}
.aib{-webkit-align-items:flex-end;align-items:flex-end;}
.cc{-webkit-justify-content: center;justify-content: center;} 
.csb{-webkit-justify-content: space-between;justify-content: space-between;}
.text-center{text-align: center !important;}
.text-left{text-align:left;}
.text-right{text-align:right;}
.self-center{ -webkit-align-self: center; align-self: center;}
.italic{font-style: italic;}
.col-reverse{ -webkit-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important;}

.mt15{ margin-top: 15px;}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: calc(100% - 30px);
    padding: 0px 15px;
  }


  @media (min-width: 576px) {
    .container {
        max-width:540px;
      }
}
@media (min-width:768px) {
    .container {
        max-width: 720px;
      }
 
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
      }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
      }
}

@media (min-width: 1360px) {
    .container {
        max-width: 1200px;
      }
}


.row>* {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.multiple.row>* {
    position: relative;
    width: 100%;
   padding:15px
}
.pdnull.row>* {
    position: relative;
    width: 100%;
   padding:0;
}
.multiple5.row>* {
    position: relative;
    width: 100%;
   padding:5px
}
.row.gutter5>*{    padding-right: 5px;
    padding-left: 5px; }
.row {
 margin-left: -15px; margin-right: -15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.row.gutter5{   margin-left: -5px; margin-right: -5px;  width: 100%;}

.col {
    -ms-flex-preferred-size: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
}

.col-1 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.3333333333%;
}
.col-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 16.666667%;
}
.col-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 25%;
}
.col-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 33.3333333333%;
   
}
.col-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 41.66666667%;
}

.col-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 50%;
}
.col-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 58.33331%;
}


.col-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 66.666667%;
}

.col-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 75%;
}




@media (max-width: 1199.98px){

    

    .col-md-12 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 100%;
    }
    .col-md-2 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-md-3 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 25%;
    }
    .col-md-4 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 33.3333333333%;   
    }
    
    .col-md-5 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 41.66666667%;
    }
    
    .col-md-6 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 50%;
    }
    .col-md-7 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 58.33331%;
    }
    
    
    .col-md-8 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 66.666667%;
    }
    .col-md-9 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 75%;
    }
}

@media(max-width: 991.98px) {
    .col-sm-reverse{ -webkit-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important;}
    .multiple-sm.row>* {
        position: relative;
        width: 100%;
       padding:15px
    }
    .col-sm-12 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 100%;
    }

    .col-sm-2 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 25%;
    }
    .col-sm-4 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 33.3333333333%;
       
    }
    
    .col-sm-5 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 41.66666667%;
    }
    
    .col-sm-6 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 50%;
    }
    .col-sm-7 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 58.33331%;
    }
    
    
    .col-sm-8 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 66.666667%;
    }
    .col-sm-9 {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        max-width: 75%;
    }
    .sm-text-center{text-align: center;}
}

@media (max-width: 575.98px) { 

    .col-xs-2 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 16.666667%;
}
.col-xs-3 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 25%;
}
.col-xs-4 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 33.3333333333%;
   
}

.col-sm-5 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 41.66666667%;
}

.col-sm-6 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 50%;
}
.col-sm-7 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 58.33331%;
}


.col-xs-8 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 66.666667%;
}
.col-xs-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 75%;
}
.col-xs-12 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 100%;
}
  }




@font-face {
  font-family: 'ews';
  src:  url(/static/media/ews.3be978c8.eot);
  src:  url(/static/media/ews.3be978c8.eot#iefix) format('embedded-opentype'),
    url(/static/media/ews.0d608552.ttf) format('truetype'),
    url(/static/media/ews.1c597097.woff) format('woff'),
    url(/static/media/ews.9ef820cc.svg#ews) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ews' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-aoe:before {
  content: "\e91e";
}
.i-aos:before {
  content: "\e91f";
}
.i-apc:before {
  content: "\e920";
}
.i-donate:before {
  content: "\e921";
}
.i-ea:before {
  content: "\e922";
}
.i-flc:before {
  content: "\e923";
}
.i-location:before {
  content: "\e924";
}
.i-mmc:before {
  content: "\e925";
}
.i-pp:before {
  content: "\e926";
}
.i-sa:before {
  content: "\e927";
}
.i-sta:before {
  content: "\e928";
}
.i-suc:before {
  content: "\e929";
}
.i-support:before {
  content: "\e92a";
}
.i-wmc:before {
  content: "\e92b";
}
.i-ywd:before {
  content: "\e92c";
}
.i-videos:before {
  content: "\e900";
}
.i-desdign:before {
  content: "\e901";
}
.i-devlopment:before {
  content: "\e902";
}
.i-envelope:before {
  content: "\e903";
}
.i-map-pin:before {
  content: "\e904";
}
.i-ui:before {
  content: "\e905";
}
.i-angle-down-f:before {
  content: "\e906";
}
.i-angle-left:before {
  content: "\e907";
}
.i-angle-left-f:before {
  content: "\e908";
}
.i-angle-right:before {
  content: "\e909";
}
.i-angle-right-f:before {
  content: "\e90a";
}
.i-angle-up:before {
  content: "\e90b";
}
.i-angle-up-f:before {
  content: "\e90c";
}
.i-call:before {
  content: "\e90d";
}
.i-category:before {
  content: "\e90e";
}
.i-edit:before {
  content: "\e90f";
}
.i-email:before {
  content: "\e92d";
}
.i-facebook:before {
  content: "\e910";
}
.i-heart:before {
  content: "\e911";
}
.i-instagram:before {
  content: "\e912";
}
.i-linkedin:before {
  content: "\e913";
}
.i-minus:before {
  content: "\e914";
}
.i-plus:before {
  content: "\e915";
}
.i-searc:before {
  content: "\e916";
}
.i-send:before {
  content: "\e917";
}
.i-tag:before {
  content: "\e918";
}
.i-twitter:before {
  content: "\e919";
}
.i-user:before {
  content: "\e91a";
}
.i-whatsapp:before {
  content: "\e91b";
}
.i-youtube:before {
  content: "\e91c";
}
.i-angle-down:before {
  content: "\e91d";
}

